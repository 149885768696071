import React from "react";
import '../../assets/css/landingpage.css'


import logoImage from "../../../src/assets/img/images/gimpyG.gif";
import katImage from "../../../src/assets/img/images/logo.png";

const Banner = (props) => {
  return (

   <div className="landingpage">
      
<div className="container">
        <div className="row justify-content-center">
          
            <div className="banner-content text-center">
            
              <h2>
              <img src={katImage} width={600} height={250} alt='gimpysworld' />
                
              </h2>
              <img src={logoImage} width={300} height={400} alt='gimpy' />
              
            </div>
            
            <div className="banner-countdown-wrap text-center"><center>
            <h2 className="title">Fed a lifetime of Chemically infused milkshakes and Franken Korn by the USDA and Big Pharma, Gimpy exists in a paradox world solana #memecoin created inadvertently by the Deepstate when they unleashed Nuclear Armageddon after losing the 2024 election to DJT.</h2>
            <h2> <a href="">CHART</a> - <a href="https://x.com/gimpyworldwtf">TWITTER</a> - <a href="https://t.me/gimpycoin">TELEGRAM</a> </h2>
            
           <h2> <a href="">CA:FExUYT7KyzBQHqkxnQ8bSPYHTFn74VugNWP1sxWYm3Rb </a> </h2>
           <h2> <a href=""></a> </h2></center>
           
                                      </div>
              
                  
                </div>
          
            </div>
          </div>
       
      
 
   
  );
};

export default Banner;
